<template>
  <div id="app">
    <raven-nav ></raven-nav>
    <router-view/>
  </div>
</template>

<script>
  import RavenNav from '@/components/RavenNav.vue'
  export default {
    name: 'App',
    components: {
      RavenNav
    },
    data() {
      return {
        date: '',
        time: ''
      }
    },
    computed: {
    },
    created() {
    },
    methods: {
    }
  }
</script>

<style lang="scss">

//初始化样式
html,body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #333;
}

html{
  background: url("~@/assets/images/background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  
 
}

.v-modal{
  display: none !important;
}



</style>
