<template>
  <div class="home w-full p-4 pb-12">
    
      <div class="home-content w-full gap-4 h-full flex justify-center items-center">
        <div class="left w-1/4 flex flex-col justify-center gap-4 items-center h-full">
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-left-1 />
          </div>
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-left-2 />
          </div>
          <div class="h-1/3 flex w-full justify-center items-center">
            <raven-left-3 />
          </div>
        </div>
        <div class="center w-1/2 flex flex-col justify-center items-center gap-4 h-full">
          <div class="h-2/3 flex w-full justify-center items-center ">
            <raven-center-1 />
          </div>
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-center-2 />
          </div>
        </div>
        <div class="right w-1/4 flex  flex-col justify-center items-center gap-4 h-full" >
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-right-1 />
          </div>
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-right-2 />
          </div>
          <div class="h-1/3 flex w-full justify-center items-center ">
            <raven-right-3 />
          </div>
        </div>
      </div>

      <raven-node-detail />
  </div>
</template>

<script>
import { mapMutations , mapState , mapActions , mapGetters } from 'vuex'
// @ is an alias to /src
import RavenLeft1 from '@/components/RavenLeft1'
import RavenLeft2 from '@/components/RavenLeft2'
import RavenLeft3 from '@/components/RavenLeft3'
import RavenRight1 from '@/components/RavenRight1.vue'
import RavenRight2 from '@/components/RavenRight2.vue'
import RavenRight3 from '@/components/RavenRight3.vue'
import RavenCenter1 from '@/components/RavenCenter1.vue'
import RavenCenter2 from '@/components/RavenCenter2.vue'
import RavenNodeDetail from '@/components/RavenNodeDetail.vue'
export default {
  name: 'HomeView',
  components: {
    RavenLeft1,
    RavenLeft2,
    RavenLeft3,
    RavenRight1,
    RavenRight2,
    RavenRight3,
    RavenCenter1,
    RavenCenter2,
    RavenNodeDetail
  },
  computed: {
   ...mapGetters(['selectedNode'])
  },
  methods: {},
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.home{
  height: calc(100vh - 6rem);
}
</style>
