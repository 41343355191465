<template>
    <div class="p-4 px-20 h-24">
        <div class="flex flex-row align-center justify-between">
            <div  class="flex item-center align-center basis-1/4">
                <div class="date text-white text-lg font-bold mr-4">{{weather}}</div>
            </div>
            <div class="flex flex-row align-center title-bg h-20 pb-8  basis-2/4">
                <!-- title -->
                <div class="title text-white text-base font-bold ">金水区新时代文明实践中心可视化平台</div>
            </div>
            <div  class="flex item-center align-center basis-1/4 justify-end">
                <div class="date text-white text-lg font-bold mr-4">{{date}}</div>
                <div class="date text-white text-lg font-bold mr-4">{{time}}</div>
            </div>
        </div>
    </div>
</template>

<script>
     export default {
        name: 'RavenNav',
        data() {
            return {
                date: '',
                time: '',
                weather: ''
            }
        },
        created() {
            
            // 定时更新时间
            setInterval(() => {
                this.updateTime();
            }, 1000);

            // 初始更新时间
            this.updateTime();
            this.getWeather();
        },
        methods: {
            // 更新时间
            updateTime() {
                this.date = new Date().toLocaleDateString();
                //年月日
                this.date = new Date().toLocaleDateString('chinese', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
                this.time = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit' , second:'2-digit'});
            },
            // 获取当日天气
            getWeather() {
                // 调用天气接口
                this.$http.get('/index/getWeather').then(response => {
                    this.weather = response.data.weather;
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
.title{
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 32px;
    color: #129BFF;
    line-height: 62px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    flex: 1;
}

.title-bg{
    background: url('~@/assets/images/image-title-bg.png') no-repeat center center;
    background-size: 100% 100%;
    padding: 0 130px;
}
</style>