import Vue from 'vue'
const _this = Vue;
const node = {
  state: {
    nodes: [],
    //当前选中的节点
    selectedNode: null,
    //是否显示节点详情
    showNodeDetail: false,
    //点击节点的坐标
    clickNodePos: null,
  },
  getters: {
    nodes: state => state.nodes,
    selectedNode: state => state.selectedNode,
    showNodeDetail: state => state.showNodeDetail,
    clickNodePos: state => state.clickNodePos,
  },
  mutations: {
    setSelectedNode(state, node) {
      state.selectedNode = node
    },
    setShowNodeDetail(state, show) {
      state.showNodeDetail = show
    },
    setNodes(state, nodes) {
      state.nodes = nodes
    },
    setClickNodePos(state, pos) {
      state.clickNodePos = pos
    },
  },
  actions: {
    getNodes({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('city.node/getAllNode').then(response => {
          commit('setNodes', response.data)
          resolve(response.data)
        })
      })
    },
    setNodes({ commit }, nodes) {
      commit('setNodes', nodes)
    },
    setSelectedNode({ commit }, node) {
      commit('setSelectedNode', node)
    },
    setShowNodeDetail({ commit }, show) {
      commit('setShowNodeDetail', show)
    },
    setClickNodePos({ commit }, pos) {
      commit('setClickNodePos', pos)
    },
  }
}

export default node