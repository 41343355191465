<template>
    <div class="raven-left1 h-full w-full p-4">
        <div class="raven-left1-title flex flex-row">
            <img src="~@/assets/images/icon-building-yard.png" alt="" class="h-6 w-6">
            <span class="title ml-2">{{ title }}</span>
        </div>
        <template v-if="!loading">
            <div class="py-4  h-full">
                <div class="h-full flex flex-col overflow-auto ">
                    <vue-seamless-scroll :data="tableData" class="h-full" :step="1" :class-option="optionSingleHeight">

                        <div class="row h-12 mb-2 flex flex-row items-center px-2 justify-between"
                            v-for="(data, index) in tableData" :key="index">
                            <div class="h-full flex flex-row items-center">
                                <img src="~@/assets/images/icon-street.png" class="w-5 h-5 mr-2">
                                <span class="text">{{ data.address }}</span>
                            </div>
                            <div class="h-full flex flex-row items-center">
                                <div class="value px-3 py-2 flex flex-row items-center">
                                    文明楼共计：{{ data.count }}
                                </div>
                            </div>
                        </div>
                    </vue-seamless-scroll>

                </div>
            </div>
        </template>
        <template v-else>
            <dv-loading>
                <div class="loading-text text-center mt-4 mb-4 text-white text-lg font-bold ">加载中...</div>
            </dv-loading>
        </template>
    </div>
</template>

<script>

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    name: "RavenLeft1",
    components: {
        vueSeamlessScroll
    },
    computed: {
        optionSingleHeight() {
            return {
                // singleHeight: 26,
                hoverStop: false,
                singleHeight: 56
            }
        }
    },
    data() {
        return {
            title: "近三年文明楼院",
            loading: false,
            //文化路街道办事处、南阳新村街道办事处、经八路街道办事处、大石桥街道办事处、人民路街道办事处、花园路街道办事处、杜岭街道办事处、南阳路街道办事处、丰产路街道办事处、北林路街道办事处、未来路街道办事处、东风路街道办事处、兴达路街道办事处、国基路街道办事处、凤凰台街道办事处、杨金路街道办事处、丰庆路街道办事处。
            // 数据随机
            tableData: [{
                count: '1000',
                address: '文化路街道办事处'
            },
            {
                count: '990',
                address: '南阳新村街道办事处'
            },
            {
                count: '980',
                address: '经八路街道办事处'
            },
            {
                count: '970',
                address: '大石桥街道办事处'
            },
            {
                count: '960',
                address: '人民路街道办事处'
            },
            {
                count: '950',
                address: '花园路街道办事处'
            },
            {
                count: '940',
                address: '杜岭街道办事处'
            },
            {
                count: '930',
                address: '南阳路街道办事处'
            },
            {
                count: '920',
                address: '丰产路街道办事处'
            },
            {
                count: '910',
                address: '北林路街道办事处'
            },
            {
                count: '900',
                address: '未来路街道办事处'
            },
            {
                count: '890',
                address: '东风路街道办事处'
            },
            {
                count: '880',
                address: '兴达路街道办事处'
            },
            {
                count: '870',
                address: '国基路街道办事处'
            },
            {
                count: '860',
                address: '凤凰台街道办事处'
            },
            {
                count: '850',
                address: '杨金路街道办事处'
            },
            {
                count: '840',
                address: '丰庆路街道办事处'
            }
            ]
        };
    },
    created() {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    }
};


</script>


<style scoped lang="scss">
.raven-left1 {
    background: url("~@/assets/images/bg-card1.png") no-repeat center center;
    background-size: 100% 100%;
}

.raven-left1-title {

    .title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 22px;
        color: #129BFF;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

.row {
    background: url('~@/assets/images/image-title-decoration.png') no-repeat center center;
    background-size: 100% 100%;

    .text {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .value {
        background: url('~@/assets/images/image-data.png') no-repeat center center;
        background-size: 100% 100%;

        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #78FBFB;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
}

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
    background-color: transparent !important;
    border: 0; //去除表格
}

/*去除底边框*/
::v-deep.el-table td.el-table__cell {
    border: 0;
}

::v-deep.el-table th.el-table__cell.is-leaf {
    border: 0;
}

/*去除底部灰条.el-table::before*/
::v-deep .el-table::before {
    display: none;
}

div::-webkit-scrollbar {
    width: 0 !important
}
</style>