<template>
    <div class="raven-left1 h-full w-full p-4 ">
        <div class="raven-left1-title flex flex-row">
            <img src="~@/assets/images/icon-moral-model.png" alt="" class="h-6 w-6">
            <span class="title ml-2">{{ title }}</span>
        </div>
        <template v-if="!loading">
            <div class="py-4  h-full">
                <div class="h-full flex flex-col overflow-auto">
                    <vue-seamless-scroll :data="userData" class="h-full" :step="1" :class-option="optionSingleHeight">
                        <div class="row h-24 mb-2 flex px-4 py-2 " v-for="(data, index) in userData"
                            :key="index">
                            <img :src="data.image" class="h-20 w-20 rounded mr-4 avatar " alt="">
                            <div class="text flex h-20 flex-col justify-between flex-1">
                                <div class="name text-lg font-bold flex">
                                    <span class="text-base mr-6">姓名:{{ data.name }}</span>
                                    <span class="ml-2 text-base ">年龄:{{ data.age }}</span>
                                </div>
                                <div class="text-sm text-gray-500 line-clamp-3">{{ data.content }}</div>
                            </div>
                        </div>
                    </vue-seamless-scroll>
                </div>

            </div>
        </template>
        <template v-else>
            <dv-loading>
                <div class="loading-text text-center mt-4 mb-4 text-white text-lg font-bold ">加载中...</div>
            </dv-loading>
        </template>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
    name: "RavenLeft1",
    components: {
        vueSeamlessScroll
    },
    computed: {
        optionSingleHeight() {
            return {
                // singleHeight: 26,
                hoverStop: false,
                singleHeight: 104,
            }
        }
    },
    data() {
        return {
            title: "道德模范",
            loading: false,
            userData: [
            ]
        };
    },
    created() {
        this.getUserData();
    },
    methods: {
        // getUserData
        getUserData() {
            this.$http.post("/volunteer.user/getList",{type:'excellent'}).then((res) => {
                this.userData = res.data;
            });
        }
    }
};


</script>


<style scoped lang="scss">
.raven-left1 {
    background: url("~@/assets/images/bg-card1.png") no-repeat center center;
    background-size: 100% 100%;
}

.raven-left1-title {

    .title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 22px;
        color: #129BFF;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

.row {
    background: url('~@/assets/images/image-title-decoration.png') no-repeat center center;
    background-size: 100% 100%;

    .text {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .value {
        background: url('~@/assets/images/image-data.png') no-repeat center center;
        background-size: 100% 100%;

        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #78FBFB;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .avatar{
        // height: 88px !important;
        // width: 88px !important;
    }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
}

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
    background-color: transparent !important;
    border: 0; //去除表格
}

/*去除底边框*/
::v-deep.el-table td.el-table__cell {
    border: 0;
}

::v-deep.el-table th.el-table__cell.is-leaf {
    border: 0;
}

/*去除底部灰条.el-table::before*/
::v-deep .el-table::before {
    display: none;
}

div::-webkit-scrollbar {
    width: 0 !important
}
</style>