<template>
    <div class="raven-left1 h-full w-full p-4 ">
        <div class="raven-left1-title flex flex-row">
            <img src="~@/assets/images/icon-campus.png" alt="" class="h-6 w-6">
            <span class="title ml-2">{{ title }}</span>
        </div>
        <template v-if="!loading">
            <div class="py-4  h-full">
                <div class="h-full flex flex-col ">
                    <div class="row h-full mb-2 flex px-4 py-2 ">
                        <div class="h-full w-full " id="chart3"></div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <dv-loading>
                <div class="loading-text text-center mt-4 mb-4 text-white text-lg font-bold ">加载中...</div>
            </dv-loading>
        </template>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: "RavenRight3",
    data() {
        return {
            title: "文明校园",
            loading: false,

        };
    },
    created() {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    mounted() {
        this.initChart();
    },
    methods: {
        initChart() {
            var chartDom = document.getElementById('chart3');
            var myChart = echarts.init(chartDom);

            var option;

            option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['文化路','经八路','人民路','花园路','南阳路','丰庆路','未来路'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} '
                        }
                    }
                ],
                series: [
                    {
                        name: '文明校园',
                        type: 'bar',
                        barWidth: '60%',
                        // 40到100随机
                        data: [95, 90, 80, 70, 60, 50, 40],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c}',
                            textStyle: {
                                color: '#fff',
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'HarmonyOS Sans SC,HarmonyOS Sans SC'
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    }
};


</script>


<style scoped lang="scss">
.raven-left1 {
    background: url("~@/assets/images/bg-card1.png") no-repeat center center;
    background-size: 100% 100%;
}

.raven-left1-title {

    .title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 22px;
        color: #129BFF;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

.row {}


div::-webkit-scrollbar {
    width: 0 !important
}
</style>