<template>
    <div class="raven-center1 h-full w-full ">
        <dv-border-box-2>
            <div class="h-full w-full flex justify-center items-center p-2" >
                <div class=" h-full w-full "  id="map"></div>
            </div>
        </dv-border-box-2>
    </div>
</template>

<script>

window._AMapSecurityConfig = {
    securityJsCode: process.env.VUE_APP_AMAP_SECURITY_CODE, // 你的密钥
}
//vuex
import { mapMutations , mapState , mapActions , mapGetters } from 'vuex'
import AMapLoader from "@amap/amap-jsapi-loader"
export default {
    name: 'RavenCenter1',
    data() {
        return {
            //点位测试数据
            map: null,
            AMap: null,
        }
    },
    async mounted() {
        await this.getNodes();
        await this.loadMap()
        this.setMarker()
    },
    computed: {
        ...mapGetters(['nodes'])
    },
    methods: {
        ...mapActions(['setSelectedNode','setShowNodeDetail','getNodes','setClickNodePos']),
        // 加载高德地图
        loadMap() {
            return new Promise((resolve, reject) => {
                var _this = this
                AMapLoader.load({
                    key: process.env.VUE_APP_AMAP_KEY,
                    version: '2.0'
                }).then((AMap) => {
                    _this.AMap = AMap
                    // 实例化地图
                    _this.map = new AMap.Map('map', {
                        resizeEnable: true,
                        //郑州市金水区
                        center: [113.66, 34.80],
                        zoom: 18
                    });
                    _this.map.setMapStyle('amap://styles/blue')
                     // 加载完成
                    resolve()
                })
            })
        },
        setMarker() {
            const _this = this
            const nodes = this.nodes
            nodes.forEach(node => {
                const marker = new this.AMap.Marker({
                    position: [node.longitude, node.latitude],
                    title: node.name,
                    icon: new this.AMap.Icon({
                    }),
                    label: {
                        content: node.name,
                        offset: new this.AMap.Pixel(0, -10),
                        background: 'transparent'
                    }
                })
                marker.setMap(_this.map)

                // 绑定点击事件
                marker.on('click', (e) => {
                    _this.setClickNodePos({x:e.originEvent.x, y: e.originEvent.y})
                    _this.setSelectedNode(node)
                    _this.setShowNodeDetail(true)
                })
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
::v-deep .amap-logo {
            display: none!important; ;
     } 
::v-deep .amap-copyright {
          bottom:-100px;
           display: none!important;;
    }  

</style>