<template>
    <div class="raven-node-detail flex " v-if="showNodeDetail" :style="{top:clickNodePos.y+20+'px',left:clickNodePos.x+20+'px',position: 'fixed'}">
        <!-- <div class="p-4 menu"  >
            <div class="flex gap-4 flex-col" >
                <div class="btn p-4 cursor-pointer">站点信息</div>
                <div class="btn p-4 cursor-pointer">视频监控</div>
                <div class="btn p-4 cursor-pointer" @click="showPosition = true">站点人员</div>
                <div class="btn p-4 cursor-pointer flex items-center justify-center" @click="handleClose">关闭</div>
            </div>
        -->
            <!-- <el-dialog :visible.sync="showNodeDetail" width="70%" @close="handleClose">
                <template slot="title">
                    <div class="raven-node-detail-nav flex justify-between gap-8">
                        <dv-border-box-2 class=" flex flex-1 cursor-pointer">
                            <div class="p-4 flex flex-col gap-4 text-lg font-bold text-white text-center"
                                @click="currentTab = 'info'">
                                站点信息
                            </div>
                        </dv-border-box-2>
                        <dv-border-box-2 class=" flex flex-1 cursor-pointer">
                            <div class="p-4 flex flex-col gap-4 text-lg font-bold text-white text-center"
                                @click="currentTab = 'video'">
                                视频监控
                            </div>
                        </dv-border-box-2>
                        <dv-border-box-2 class=" flex flex-1 cursor-pointer">
                            <div class="p-4 flex flex-col gap-4 text-lg font-bold text-white text-center"
                                @click="currentTab = 'person'">
                                站点人员
                            </div>
                        </dv-border-box-2>
                    </div>
                </template>

                <div class="raven-node-detail-content ">
                    <dv-border-box-10 v-if="currentTab == 'info'" class="flex flex-col gap-4 h-full">
                        <div class="flex flex-col justify-between gap-4  p-4 h-full">
                            <div class="flex-1">

                                <el-carousel :interval="4000" class="w-full h-full">
                                    <el-carousel-item v-for="image in selectedNode.images" :key="image" class="flex justify-center">
                                        <el-image class="h-full w-full" fit="fill"  :src="image"></el-image>
                                    </el-carousel-item>
                                </el-carousel>

                            </div>

                            <div class="flex flex-row gap-4 pt-8 ">

                                <div class="p-4 basis-1/4 flex flex-col gap-4 text-lg font-bold text-white text-center">
                                    站点名称：{{ selectedNode.name }}
                                </div>

                                <div class="p-4 basis-1/4 flex flex-col gap-4 text-lg font-bold text-white text-center">
                                    站点地址：{{ selectedNode.address }}
                                </div>

                                <div class="p-4 basis-2/4 flex flex-col gap-4 text-lg font-bold text-white text-center">
                                    站点介绍：{{ selectedNode.content }}
                                </div>
                                
                            </div>
                        </div>
                    </dv-border-box-10>
                    <dv-border-box-10 v-if="currentTab == 'video'" class="">
                        <div class="flex flex-row flex-wrap p-4  h-full ">
                            <div class="flex flex-row flex-wrap h-full overflow-auto w-full">
                                <div class=" h-96 basis-1/2 p-4" v-for="(live, index) in selectedNode.lives"
                                    :key="index">
                                    <dv-border-box-13>
                                        <div class="p-4 h-full flex flex-col justify-between ">
                                            <iframe
                                                src="https://player.bilibili.com/player.html?aid=66666666&page=1"
                                                scrolling="no" border="0" frameborder="no" framespacing="0"
                                                allowfullscreen="true" width="100%" height="100%"></iframe>
                                        </div>
                                    </dv-border-box-13>
                                </div>
                            </div>
                        </div>

                    </dv-border-box-10>
                    <dv-border-box-10 v-if="currentTab == 'person'" class="">
                        <div class="flex flex-row flex-wrap p-4   ">
                            <div class="flex flex-row flex-wrap h-full overflow-auto w-full">
                                <div class=" basis-1/4 " v-for="(person, index) in selectedNode.position"
                                    :key="index">
                                    <dv-border-box-12>
                                        <div  class="p-4 py-10 w-full flex flex-col justify-between items-center gap-2" @click="trtcShow = true">
                                            <el-image class="w-32 h-32" :src="person.avatar"></el-image>
                                            <div class="text-lg font-bold text-white text-center">{{ person.nickname }}
                                            </div>
                                            <div class="text-lg font-bold text-white text-center">{{ person.name
                                                }}
                                            </div>
                                            <div class="text-lg font-bold text-white text-center">{{ person.mobile
                                                }}</div>
                                        </div>
                                    </dv-border-box-12>
                                </div>
                            </div>
                        </div>
                    </dv-border-box-10>
                </div>

            </el-dialog> -->
        <!-- </div> -->
        <div class="p-4 position flex flex-col gap-4 max-h-96 overflow-auto" v-if="showNodeDetail">
            <!-- 关闭icon -->
            <div class="flex ">
                <div class="flex justify-center">
                   
                </div>
                <div class="flex justify-center flex-1 text-white text-lg font-bold ">
                    点位人员
                </div>
                <div class="flex justify-end">
                    <img src="@/assets/images/icon-close.png" class="w-6 h-6 cursor-pointer" @click="handleClose" alt="">
                </div>
            </div>
            <div class="row py-5 pl-20 pr-8 flex  gap-4" v-for="(v,index) in selectedNode.position" :key="index">
                <div class="username flex-1 flex items-center  justify-center">
                    {{ selectedNode.name }}
                </div>
                <div class="username flex-1 flex items-center  justify-center">
                    {{ v.name }}
                </div>
                <div class="position-name flex-1 flex items-center justify-center">
                    {{ v.nickname }}
                </div>
                <div class="video flex-1 flex justify-end items-center">
                    <img src="@/assets/images/icon-video-call.png" class="w-6 h-6" alt="" @click="setTrtcShow(true,v.user_id)">
                    <!-- <img src="@/assets/images/icon-video-call.png" class="w-6 h-6" alt="" @click="setTrtcShow(true,21)"> -->
                </div>
            </div>
        </div>

        <nrtc :remote-uid="remoteUid" v-if="trtcShow" :visible="trtcShow" @close="setTrtcShow(false)"></nrtc>
    </div>
</template>

<script>
//vuex
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Nrtc from '@/components/Nrtc.vue'
export default {
  components: { Nrtc },
    name: 'RavenNodeDetail',
    data() {
        return {
            //当前选择的tab
            currentTab: 'info',
            avatarUrl: require('@/assets/testimg/avatar4.jpg'),
            imageUrls:[],
            trtcShow: false,
            showPosition: false,
            remoteUid: '21'
        }
    },
    computed: {
        ...mapGetters(['showNodeDetail', 'selectedNode','clickNodePos'])
    },
    methods: {
        ...mapActions(['setShowNodeDetail']),
        handleClose() {
            this.setShowNodeDetail(false)
        },
        setTrtcShow(value,remoteUid = '') {
            this.remoteUid = remoteUid
            this.trtcShow = value
        },
    }
}

</script>

<style lang="scss" scoped>
.menu{
    background: url('~@/assets/images/bg-menu.png') no-repeat;
    background-size: 100% 100%;

    .btn{
        background: url('~@/assets/images/icon-menu-btn.png') no-repeat;
        background-size: 100% 100%;
        
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 11px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

}

.position{
    background: url('~@/assets/images/bg-position.png') no-repeat;
    background-size: 100% 100%;

    .row{
        background: url('~@/assets/images/bg-position-row.png') no-repeat;
        background-size: 100% 100%;
        width: 645px;
        
        .username{
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }

        .position-name{
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }

        .video{
            
        }
    }
}

::v-deep .el-dialog {
    background: url('~@/assets/images/bg.png');
    background-size: 100% 100%;
    height: 80vh;
    margin-top: 10vh !important;
}

::v-deep .el-carousel__container {
    height: 100%;
}

::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
</style>
