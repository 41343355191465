<template>
    <div class="raven-left1 h-full w-full p-4 ">
        <div class="raven-left1-title flex flex-row">
            <img src="~@/assets/images/icon-campus.png" alt="" class="h-6 w-6">
            <span class="title ml-2">{{ title }}</span>
        </div>
        <template v-if="!loading">
            <div class="py-4  h-full  overflow-x-auto w-full">
                <div class="h-full flex gap-4 overflow-x-auto w-full">
                    <div class="item flex  flex-col w-80 h-full mb-2 flex px-4 py-2 gap-4 shrink-0 overflow-auto"
                        v-for="(data, index) in userData" :key="index">
                        <div class="flex flex-row justify-between pt-4">
                            <img :src="data.image" class="h-20 w-20 rounded mr-4 avatar" alt="">
                            <div class="flex flex-col gap-2 flex-1">
                                <div class="name text-base  font-bold text-white">{{ data.name }}</div>
                                <div class="age text-base text-white">{{ data.age }}岁</div>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="text-sm text-gray-500 text-overflow">{{ data.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <dv-loading>
                <div class="loading-text text-center mt-4 mb-4 text-white text-lg font-bold ">加载中...</div>
            </dv-loading>
        </template>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import * as echarts from 'echarts';
export default {
    components: {
        vueSeamlessScroll
    },
    name: "RavenCenter2",
    computed: {
        optionSingleHeight() {
            return {
                // singleHeight: 26,
                hoverStop: false,
                direction:2
            }
        },
    },
    
    data() {
        return {
            title: "优秀志愿者",
            loading: false,
            userData: []
        };
    },
    created() {
    },
    mounted() {
        this.getUserData();
    },
    methods: {
        //getUserData
        getUserData() {
            this.$http.post("/volunteer.user/getList", { type: 'morality' }).then((res) => {
                this.userData = res.data;
            });
        }
    }
};


</script>


<style scoped lang="scss">
.raven-left1 {
    background: url("~@/assets/images/bg-card1.png") no-repeat center center;
    background-size: 100% 100%;
}

.raven-left1-title {

    .title {
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        font-size: 22px;
        color: #129BFF;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

.item {
    background: url('~@/assets/images/bg-volunteer-card.png') no-repeat center center;
    background-size: 100% 100%;
}


div::-webkit-scrollbar {
    width: 0 !important
}
</style>