import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import NIM from "@yxim/nim-web-sdk/dist/SDK/NIM_Web_NIM"
import { NECall } from '@xkit-yx/call-kit'

// 引入插件
import '@/raven/plugin/index.js'


Vue.config.productionTip = false

const neCall = NECall.getInstance()

const nim = NIM.getInstance({
  appKey: process.env.VUE_APP_NIM_KEY,
  account: process.env.VUE_APP_NIM_ACCOUNT,
  token: process.env.VUE_APP_NIM_TOKEN,
  logLevel: 'debug',
  onconnect: async () => {
      
  },
  ondisconnect: () => {
      
  },
});

Vue.prototype.$neCall = neCall
Vue.prototype.$nim = nim

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
