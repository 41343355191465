import Vue from 'vue'
import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000
})


// 请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    return config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    if(response.data.code != 1)
    {
        
        return Promise.reject(response.data.msg)
    }
    
    return response.data
    
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

console.log('http', http)

Vue.prototype.$http = http
