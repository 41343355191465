<template>
    <div>
        <el-dialog title="" :visible="visible" @close="handleClose" :before-close="handleBeforeClose" width="70%"
            center>
            <div class="p-4 w-full h-full">
                <div id="remoteView" class="w-full h-full remoteView flex justify-center items-center">
                    <div class="text-white text-lg " v-if="!currentCallStatus">正在接通中</div>
                </div>
                <div id="localView"></div>
                <!-- 挂断icon -->
                <div class="absolute right-0 top-0 m-4">
                    <el-button type="danger" icon="el-icon-phone" @click="hangup">挂断</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { resolve } from "@easydarwin/easyplayer";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        remoteUid: {
            type: Number,
            default: ''
        }
    },
    data() {
        return {
            //接通状态
            currentCallStatus: false,
        }
    },
    mounted() {
        console.warn('this.$nim', this.$nim)
        console.warn('this.$neCall', this.$neCall)
        this.init()
    },
    methods: {
        async init() {
            var _this = this;
            var params = {
                appKey: process.env.VUE_APP_NIM_KEY,
                account: process.env.VUE_APP_NIM_ACCOUNT,
                token: process.env.VUE_APP_NIM_TOKEN,
            }

            console.warn('init', params)
            await this.initCall()
            await this.call()

        },
        initCall() {
            console.warn('initCall')
            var _this = this;
            return new Promise((resolve,reject)=>{
                _this.$neCall.setup({
                    nim: _this.$nim,
                    appkey: process.env.VUE_APP_NIM_KEY,
                    rtcConfig: {
                        videoResolution: 'VIDEO_QUALITY.VIDEO_QUALITY_720p',
                        videoFrameRate: 'VIDEO_FRAME_RATE.CHAT_VIDEO_FRAME_RATE_NORMAL',
                        audioQuality: 'speech_low_quality',
                    },
                })

                // 来电监听
                _this.$neCall.on('onReceiveInvited', (value) => {
                    // 用户可以触发视图，比如弹起邀请页面
                    console.log(value)
                    this.message = `收到 ${value.callerAccId} 呼叫，可以点击接听按钮了`

                })
                // 通话结束的回调。通话被取消、拒绝、通话中挂断，即所有导致通话结束的行为都会触发此回调
                _this.$neCall.on('onCallEnd', (value) => {
                    console.log(value)
                    // this.message = ''
                    this.handleClose()
                })
                // 话单回调
                _this.$neCall.on('onRecordSend', (value) => {
                    console.log(value)
                })
                // 主被叫加入通道的回调，通话建立后触发
                _this.$neCall.on('onCallConnected', (value) => {
                    console.log(value)
                    _this.currentCallStatus = true
                })

                resolve()
            })
        },
        handleClose() {
            this.destory()
            this.$emit('close')
        },
        handleBeforeClose(done) {
            // 关闭之前，先离开频道
            this.destory()
            this.$emit('close')
        },
        destory() {
        },
        async call() {
            var _this = this;
            var params = {
                accId: 'user_' + _this.remoteUid, // 被叫 im 的 id
                callType: '2', //呼叫类型，1表示语音通话，2表示视频通话
            }
            console.warn('call', params)
            this.$neCall.call(params)
            const localView = document.getElementById('localView')
            const remoteView = document.getElementById('remoteView')
            this.$neCall.setRemoteView(remoteView, 'user_' + _this.remoteUid)
            this.$neCall.setLocalView(localView)
        },
        hangup() {
            this.$neCall.hangup()
            this.handleClose()
        }
    }
};
</script>

<style scoped>
/* 样式 */

::v-deep .el-dialog {
    background: url('~@/assets/images/bg.png');
    background-size: 100% 100%;
    height: 80vh;
    margin-top: 10vh !important;
}

::v-deep .el-carousel__container {
    height: 100%;
}

::v-deep .el-dialog--center .el-dialog__body {
    height: 100%;
}

.v-modal {
    display: none !important;
}
</style>